.container-img {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url(../public/img/landingImg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.title {
  font-weight: 500;
}
.label {
  font-weight: 500;
}
.map-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.map-input {
  width: 250px;
  height: 32px;
  padding: 0 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.map-input-two {
  margin-left: 20px;
}
.user-info {
  display: flex;
  margin: 10px 5px 20px 5px;
  align-items: center;
  color: white;
  gap: 5px;
  cursor: pointer;
}
.tendertruck-client-box {
  width: 60%;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 21px;
  padding: 2%;
  padding-top: 0%;
  margin-top: 2%;
  border-top: none;
  margin: 1%;
  border-radius: 15;
  background: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.div-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* style */
.hero-none-mobile {
  display: block;
}

/* mobile version */
@media (max-width: 768px) {
  .hero-none-mobile {
    display: none;
  }

  .pattern-home-hero {
    position: relative;
    display: block;
    top: 0;
    left: 0;
  }
}

.hero-none {
  display: none;
}

/* mobile version */
@media (max-width: 768px) {
  .hero-none {
    display: block;
  }

  .pattern-home-hero {
    position: relative;
    display: block;
    top: 0;
    left: 0;
  }
}

.margin-zero {
  margin-bottom: 0px !important;
}

.btn-download-primary {
  color: #fff !important;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
}

.custom-tab-container > .ant-tabs-nav > .ant-tabs-nav-wrap {
  justify-content: center;
}

.custom-tab-container
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list {
  border: 0.5px solid #c4c4c4;
  border-radius: 10px;
}

.custom-tab-container
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  border-radius: 10px;
  border-bottom: none;
  padding: 15px 50px;
  border: none;
  background-color: #fefefe;
}

.custom-tab-container
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active {
  background: #3a2fe9;
}

.custom-tab-container
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: #fefefe;
}
